import React from 'react'
import globalConfig from '../shared/config/common/globalConfig'
import DocumentLayout from '../DocumentLayout'
import Centered from '../Centered'

const SignupComplete = () => (
  <DocumentLayout className="subpage-blue">
    <Centered>
      <div className="spacing">
        <h1>Thank you for your signup!</h1>
        <hr />
        <p>Be sure to check your email for validation instructions.</p>
        <p>
          <a
            href={`${globalConfig.app.protocol}://${globalConfig.app.host}/auth/login`}
            className="button outline-light">
            Go to Login Page
          </a>
        </p>
      </div>
    </Centered>
  </DocumentLayout>
)

export default SignupComplete
